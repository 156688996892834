import {createApp} from "vue";
import { createWebHistory, createRouter } from "vue-router";
import store from './store'
// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

// views without layouts

import Landing from "@/views/Landing.vue";
// import Profile from "@/views/Profile.vue";
import ListCampaign from "@/views/campaign/list.vue";
import CampaignDetail from "@/views/campaign/detail.vue";
import CampaignClaim from "@/views/campaign/claim.vue";
import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/project/:id/campaigns",
    component: ListCampaign,
  },
  {
    path: "/campaigns/:id",
    component: CampaignDetail,
  },
  {
    path: "/campaigns/:id/claim",
    component: CampaignClaim,
  },
  {
    path: "/",
    component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).use(store).mount("#app");
