import formatImage from './image';
import formatToken from './tokens';
import formatCampaignType from './campaign-type';
import moment from 'moment';

function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
}
function convertRangeToTime(value) {
    if (value === 86400) {
        return 'Daily'
    }
    if (value === 60 * 60 * 24 * 30) {
        return 'Monthly'
    }
}

function findUserReward(userAddress, rewards) {
    rewards.forEach(reward => {
        if(reward.address == userAddress) {
            return reward.amount
        }
    })
    return 0;
}
export {
    formatImage,
    formatDate,
    formatToken,
    formatCampaignType,
    findUserReward,
    convertRangeToTime
}