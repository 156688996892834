const TOKEN_AIZA = process.env.VUE_APP_TOKEN_AIZA

const Tokens = [
    {
        address: TOKEN_AIZA,
        name: 'AIZA'
    }
] 
export default function (address) {
    let tokenName = 'Unknown';
    Tokens.forEach(token => {
        if(token.address === address) tokenName = token.name; 
    });
    return tokenName;
}
