<template>
  <nav class="w-full flex flex-wrap items-center justify-between navbar-expand-lg bg-blueBlack shadow">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <router-link to="/">
          <a class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase">
            <img :src="logo" alt="..." />
          </a>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button" v-on:click="setNavbarOpen">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div class="lg:flex flex-grow items-center" :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning">
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li
            class="active:bg-lightBlue-50  hover:text-blueGray-300 text-blueGray-500 px-3 py-2 flex items-center  uppercase font-bold">
            <router-link to="/">
              Airdrop Best
            </router-link>
          </li>
          <li
            class="active:bg-lightBlue-50 hover:text-blueGray-300 text-blueGray-500 px-3 py-2 flex items-center  uppercase font-bold">
            <router-link to="/">
              About us
            </router-link>
          </li>
          <li
            class="active:bg-lightBlue-50 hover:text-blueGray-300 text-blueGray-500 px-3 py-2 flex items-center  uppercase font-bold">
            <router-link to="/">
              Game
            </router-link>
          </li>
          <li
            class="active:bg-lightBlue-50 hover:text-blueGray-300 text-blueGray-500 px-3 py-2 flex items-center  uppercase font-bold">
            <router-link to="/">
              Marketplace
            </router-link>
          </li>
        </ul>
      </div>
      <div class="lg:flex flex-grow items-center" :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning">
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <button
              v-if="account"
              @click="clickedDisconnect"
              class="max-w-150-px truncate bg-lightBlue-600 text-white active:bg-lightBlue-200 font-bold px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button">
              {{account}}
            </button>
            <button
              v-else
              class="bg-lightBlue-600 text-white active:bg-lightBlue-200 font-bold px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button" @click="clickedConnect">
              <span><i class="fas fa-sign-in-alt mr-1"></i> Login</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import logo from "@/assets/img/logo-2.png";
  import {
    mapState
  } from 'vuex';
  export default {
    data() {
      return {
        navbarOpen: false,
        logo
      };
    },
    methods: {
      setNavbarOpen: function () {
        this.navbarOpen = !this.navbarOpen;
      },
      clickedConnect() {
        this.$store.dispatch('eth/connect')
      },
      clickedDisconnect() {
        this.$store.dispatch('eth/disconnect')
      },
    },
    computed: {
      ...mapState('eth', {
         account: state => state.account,
      }),
    },
    props: ['web3Modal'],
  };
</script>