import {
  CAMPAIGN,
  PROOF
} from './api'
import {
  request,
  METHOD
} from '@/utils/request'


export async function getList(params) {
  return request(CAMPAIGN, METHOD.GET, params)
}

export async function getById(id) {
  return request(`${CAMPAIGN}/${id}`, METHOD.GET)
}

export async function getProof(id, params) {
  return request(`${PROOF}/${id}/proof`, METHOD.GET, params)
}

export async function updateClaimed(id, payload) {
  return request(`${CAMPAIGN}/${id}/claimed`, METHOD.PUT, payload);
}

export async function getReward(url) {
  return request(url, METHOD.GET)
}