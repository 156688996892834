<template>
  <footer class="relative bg-blueBlack pt-8 pb-6 text-white">
    <div class="container mx-auto px-4">
      <div class="flex justify-center lg:text-left items-center">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="">© airdropbest</h4>
        </div>
        <div class="w-full lg:w-6/12 px-4 flex justify-end items-center">
          <div class="mr-2">support@airdropbest.com</div>
          <div class="lg:mb-0 mb-6 flex justify-end">
            <a href="#" class="mr-2">
              <img :src="youtube" class="h-10 w-10" alt="..." />
            </a>
            <a href="#" class="mr-2">
              <img :src="twitter" class="h-10 w-10" alt="..." />
            </a>
            <a href="#" class="mr-2">
              <img :src="facebook" class="h-10 w-10" alt="..." />
            </a>
            <a href="#" class="mr-2">
              <img :src="discord" class="h-10 w-10" alt="..." />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  import youtube from "@/assets/img/YouTube.png";
  import twitter from "@/assets/img/Twitter.png";
  import facebook from "@/assets/img/Facebook.png";
  import discord from "@/assets/img/discord.png";
  export default {
    data() {
      return {
        youtube,
        twitter,
        facebook,
        discord,
        date: new Date().getFullYear(),
      };
    },
  };
</script>