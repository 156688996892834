const CampaignType = {
    RANDOM: 'Random',
    STANDARD: 'Standard',
    STAGE2STAGE: 'Stage to stage',
}

export default function (type) {
    let formatedType = 'Unknown';

    Object.keys(CampaignType).forEach(function(key) {
        if (type === key) formatedType = CampaignType[key];
      });
    
    return formatedType;
}