// MY INFURA_ID, SWAP IN YOURS FROM https://infura.io/dashboard/ethereum
export const INFURA_ID = '5ce0898319eb4f5c9d4c982c8f78392a'

// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
export const ETHERSCAN_KEY = 'PSW8C433Q667DVEX5BCRMGNAH9FSGFZ7Q8'

export const CampaignStatus = {
    DRAFT: 'DRAFT',
    PENDING: 'PENDING',
    DOING: 'DOING',
    FINISHED: 'FINISHED',
}
