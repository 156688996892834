<template>
  <div>
    <section class="py-8"
      style="background: linear-gradient(220.52deg, #792BF8 18.67%, rgba(0, 222, 49, 0.75) 127.27%); min-height: 100vh;">
      <div class="container mx-auto">
        <div class="flex justify-start mb-4 text-white font-bold">
          AIZAWORLD > <span style="color: #999999">LIST AIRDROP</span>
        </div>
        <div class="flex justify-end mb-4">
          <div class="mb-3 mr-2">
            <select @change="selectStatus" v-model="selectedStatus"
              class="min-w-140-px form-select px-3 py-1.5 text-white bg-blueGray-300 rounded" aria-label="status">
              <option value="" selected>Status</option>
              <option v-for="status in listStatus" :value="status" :key="status">{{status}}</option>
            </select>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-6">
          <div v-for="campaign in listCampaign" :key="campaign.id"
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueBlack grow grow:hover">
            <router-link :to="'/campaigns/'+campaign.id">
              <img :alt="campaign.name" :src="formatImage(campaign.photo)" class="w-full align-middle rounded-t-lg object-cover" style="height: 220px">
            </router-link>
            <span class="bg-opacity-8 text-white p-2 absolute rounded" style="top: 10px;right: 10px;">
              {{formatToken(campaign.tokenAddress)}}: {{Number(campaign.totalToken).toLocaleString()}}
            </span>
            <div class="flex justify-between py-5 px-3 text-white">
              <img class="rounded h-24 w-24 border-solid border border-blue" :src="formatImage(campaign.logo)"
                alt="unsplash image">
              <div class="flex flex-col justify-center px-2">
                <p class="my-2">{{campaign.name}}</p>
                <p class="my-2 flex justify-between items-center">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 9H7.6V10.6667H6V9ZM6 12.3333H7.6V14H6V12.3333ZM9.2 9H10.8V10.6667H9.2V9ZM9.2 12.3333H10.8V14H9.2V12.3333ZM12.4 9H14V10.6667H12.4V9ZM12.4 12.3333H14V14H12.4V12.3333Z"
                      fill="white" />
                    <path
                      d="M3.77778 18H16.2222C17.2027 18 18 17.2824 18 16.4V5.2C18 4.3176 17.2027 3.6 16.2222 3.6H14.4444V2H12.6667V3.6H7.33333V2H5.55556V3.6H3.77778C2.79733 3.6 2 4.3176 2 5.2V16.4C2 17.2824 2.79733 18 3.77778 18ZM16.2222 6.8L16.2231 16.4H3.77778V6.8H16.2222Z"
                      fill="white" />
                  </svg>
                 <span style="font-size: 0.9em;">{{formatDate(campaign.startTime)}} - {{formatDate(campaign.endTime)}}</span>
                </p>
              </div>
              <div class="flex items-center">
                <a class="text-white text-xs px-4 py-2 rounded shadow outline-none mb-3" :class="campaign.status === 'DOING' ? 'bg-primary' : campaign.status === 'PENDING' ? 'bg-dark' : 'bg-emerald-600'">
                  {{campaign.status}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {
    formatImage,
    formatDate,
    formatToken
  } from "../../filters";

  import {
    getList
  } from '@/services/campaign';
  import {
    CampaignStatus
  } from '../../constants';
  import moment from 'moment';

  export default {
    data() {
      return {
        listCampaign: null,
        listStatus: [CampaignStatus.PENDING, CampaignStatus.DOING, CampaignStatus.FINISHED],
        selectedStatus: '',
        currentTime: moment()
      };
    },
    mounted: async function () {
      try {
        const params = {
          page: 1,
          take: 50,
          projectId: 1
        }
        const response = await getList(params);
        const result = response.data.data.map(campaign => {
          let status = campaign.status;
          const endTime = moment(campaign.endTime);
          if(endTime.isBefore(this.currentTime)){
            status =  CampaignStatus.FINISHED;
          }
          return {...campaign, ...{status}}
        })
        const listStatus =  [CampaignStatus.PENDING, CampaignStatus.DOING, CampaignStatus.FINISHED];

        this.listCampaign = result.filter(campaign => listStatus.includes(campaign.status));
      } catch (error) {
        console.log(error);
        this.listCampaign = []
      }
    },
    methods: {
      async selectStatus(event) {
        const status = event.target.value
        const projectId = this.$route.params.id;
        try {
          const params = {
            page: 1,
            take: 50,
            projectId
          }
          let listStatus = [CampaignStatus.PENDING, CampaignStatus.DOING, CampaignStatus.FINISHED];
          if (status && status!== CampaignStatus.FINISHED) {
            params['status'] = status;
            listStatus = [status]
          }
          if(status == CampaignStatus.FINISHED) {
            listStatus = [CampaignStatus.FINISHED]
          }
          const response = await getList(params);
          const result = response.data.data.map(campaign => {
            let status = campaign.status;
            const endTime = moment(campaign.endTime);
            if(endTime.isBefore(this.currentTime)){
              status =  CampaignStatus.FINISHED;
            }
            return {...campaign, ...{status}}
          })
        this.listCampaign = result.filter(campaign => listStatus.includes(campaign.status));
        } catch (error) {
          console.log(error);
          this.listCampaign = []
        }

      },
      formatStatus(campaign) {
        const endTime = moment(campaign.endTime);
        if(endTime.isBefore(this.currentTime)){
          return CampaignStatus.FINISHED;
        } else {
          return campaign.status;
        } 
      },
      formatImage,
      formatDate,
      formatToken
    }
  };
</script>