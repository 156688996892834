<template>
  <div>
    <section class="py-8"
      style="background: linear-gradient(220.52deg, #792BF8 18.67%, rgba(0, 222, 49, 0.75) 127.27%); min-height: 100vh;">
      <div v-if="!loading" class="container mx-auto">
        <div class="flex justify-start mb-4 text-white font-bold">
          AIZAWORLD > <router-link :to="`/project/1/campaigns`">LIST AIRDROP</router-link> > <router-link :to="`/campaigns/${campaign.id}`">VIEW AIR-DROP</router-link> > <span style="color: #999999">Disstribution Portal</span>
        </div>
        <div class="flex justify-center">
          <div
            class="w-full xl:w-8/12 sm:w-full shadow-lg rounded-lg flex justify-center items-center p-5 bg-blueBlack text-white">
            <div class="w-1/2 flex-col justify-center">
              <p class="text-center mb-3">AIZA WORLD Disstribution Portal</p>
              <div class="bg-white rounded-lg flex justify-center items-center p-5 mb-3">
                <img :src="metamask" class="h-12 w-12 mr-4" alt="..." />
                <span v-if="campaign.mappingId && account" class="max-w-150-px truncate text-black">
                  {{account}}
                </span>
                <span v-else @click="connect" class="text-black">
                  Connect
                </span>
              </div>
              <div v-if="account" @click="disconnect" class="mb-3 rounded-lg text-center flex justify-center"
                style="background: #001223; padding: 10px 20px 10px 10px;">
                Disconnect
              </div>
              <div class="mb-3 rounded-lg text-center flex justify-center" style="background: #001223; padding: 10px 20px 10px 10px; border: 1px solid #0A5891; box-sizing: border-box;">
                {{campaign.name}}
              </div>
              <div v-if="campaignOnchain" class="mb-3 rounded-lg text-center flex justify-center" style="background: #001223; padding: 10px 20px 10px 10px; border: 1px solid #0A5891;
box-sizing: border-box;">
                Your available: {{userRedeemable}} {{formatToken(campaignOnchain.token)}}
              </div>
              <div v-if="campaignOnchain" class="mb-3 rounded-lg text-center flex justify-center" style="background: #001223; padding: 10px 20px 10px 10px; border: 1px solid #0A5891;
box-sizing: border-box;">
                You Claimed: {{userClaimed}} {{formatToken(campaignOnchain.token)}}
              </div>
              <div v-if="campaignOnchain" class="mb-3 rounded-lg text-center flex justify-center" style="background: #001223; padding: 10px 20px 10px 10px; border: 1px solid #0A5891;
box-sizing: border-box;">
                Avaiable: {{totalAvailable}} {{formatToken(campaignOnchain.token)}}
              </div>
              <button
                v-if="campaignOnchain && campaignOnchain.isActive && userRedeemable != 0 && !checkFinished(campaign.endTime)"
                @click="redeem(campaign.id, campaign.mappingId, userRedeemable)"
                class="bg-lightBlue-600 text-white font-bold px-4 py-3 rounded shadow mb-3 w-full disabled:opacity-50"
                type="button"
                :disabled="disableButton"
                >
                <i class="fas fa-sign-in-alt mr-1"></i> Claimdrop
              </button>
              <div class="mb-3 text-center flex justify-center">
                With stage2stage distribution if you do not grab the reward, it is likely that the total value of the reward has reached the daily limit. Please come back tomorrow
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container mx-auto text-white">
        LOADING...
      </div>
    </section>
  </div>
</template>
<script>
  import {
    formatImage,
    formatDate,
    formatToken,
    formatCampaignType
  } from "../../filters";
  import {
    getById,
    getReward,
    getProof,
    updateClaimed
  } from '../../services/campaign';


  import metamask from "@/assets/img/metamask.png";

  import {
    mapState,
  } from 'vuex';

  import {utils, FixedNumber} from 'ethers';
  import moment from 'moment';

  export default {
    data() {
      return {
        metamask,
        loading: true,
        campaign: null,
        campaignOnchain: null,
        redeemable: null,
        hasClaimed: true,
        userRedeemable: null,
        userClaimed: null,
        rewards: null,
        totalAvailable: 0,
        disableButton: false,
      };
    },
    methods: {
      formatImage,
      formatDate,
      formatToken,
      formatCampaignType,
      checkFinished(endTime) {
        const currentTime = moment();
        const end = moment(endTime);
        console.log(end.isBefore(currentTime));
        if(end.isBefore(currentTime)){
          return true
        } else {
          return false;
        } 
      },
      disconnect: function () {
        this.$store.dispatch('eth/disconnect')
      },
      connect: function () {
        this.$store.dispatch('eth/connect')
      },
      formatAmount(amount) {
        return amount ? FixedNumber.fromValue(amount).divUnsafe(FixedNumber.fromValue(utils.parseEther('1'))).toString() : 0;
      },
      async getHasClaimed () {
        try {
          this.hasClaimed = await this.airdropContract.hasClaimed(this.campaign.mappingId, this.account);
        } catch (error) {
          console.log(error);
          this.hasClaimed = true;
        } 
      },
      async getRedeemable () {
        try {
          this.redeemable = await this.airdropContract.redeemable(this.account, this.campaign.mappingId);
          console.log(this.redeemable)
        } catch (error) {
          console.log(error);
          this.redeemable = 0;
        } 
      },
      async redeem(id, mappingId, amount) {
        this.disableButton = true;
        const formatedAmount = utils.parseEther(amount.toString()).toString();
        const response = await getProof(id, {address: this.account, amount: formatedAmount});
        const proof = response.data;
        console.log('proof', proof);
        this.$store.dispatch('eth/redeem', {id: mappingId, amount: formatedAmount, proof}).then(async (isSuccess) => {
          if(isSuccess) {
            this.disableButton = false;
            this.userRedeemable = 0;
            this.userClaimed = amount;
            this.totalAvailable = this.totalAvailable - amount;
            this.campaignOnchain = await this.airdropContract.campaigns(this.campaign.mappingId);
            await updateClaimed(this.$route.params.id, {claimed: this.formatAmount(this.campaignOnchain.redeemedAmount)})
          } else {
            alert('Something went wrong, try again later')
            this.disableButton = false;
          }
        })
      },
      findUserReddemable(arr) {
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if(element.address == this.account) {
            return element.amount;
          } 
        }
        return 0;
      }
    },
    created: async function () {
      try {
        this.loading = true;
        const response = await getById(this.$route.params.id);
        this.campaign = response.data;
        const rewardsRes = await getReward(formatImage(this.campaign.reward));
        this.rewards = rewardsRes.data;
        await this.getHasClaimed()

        this.campaignOnchain = await this.airdropContract.campaigns(this.campaign.mappingId);
        this.totalAvailable = this.campaign.totalToken - Number(this.formatAmount(this.campaignOnchain.redeemedAmount));
        const userReward = this.findUserReddemable(this.rewards);
        if(!this.hasClaimed) {
          await this.getRedeemable();
          if(userReward == 0 ) {
            this.userRedeemable = 0
            this.userClaimed = 0;
          } else if(this.redeemable.lt(utils.parseEther(userReward.toString()))) {
            this.userRedeemable = 0
            this.userClaimed = 0;
          } else {
            this.userRedeemable = userReward
            this.userClaimed = 0;
          }
        } else {
            this.userRedeemable = 0
            this.userClaimed = userReward
        }
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    computed: {
      ...mapState('eth', {
        account: state => state.account,
        airdropContract: state => state.airdropContract
      }),
    },
    watch: {
      async airdropContract(newVal) {
        try {
          const response = await getById(this.$route.params.id);
          const campaign = response.data;
          const rewardsRes = await getReward(formatImage(this.campaign.reward));
          this.rewards = rewardsRes.data;
          await this.getHasClaimed()

          this.campaignOnchain = await newVal.campaigns(campaign.mappingId);
          this.totalAvailable = this.campaign.totalToken - Number(this.formatAmount(this.campaignOnchain.redeemedAmount));
          await this.getRedeemable();
          const userReward = this.findUserReddemable(this.rewards);
          if(!this.hasClaimed) {
          await this.getRedeemable();
            if(userReward == 0 ) {
              this.userRedeemable = 0
              this.userClaimed = 0;
            } else if(this.redeemable.lt(utils.parseEther(userReward.toString()))) {
              this.userRedeemable = 0
              this.userClaimed = 0;
            } else {
              this.userRedeemable = userReward
              this.userClaimed = 0;
            }
          } else {
            this.userRedeemable = 0
            this.userClaimed = userReward
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
</script>