<template>
  <div id="app">
    <index-navbar/>
    <router-view />
    <footer-component />
  </div>
</template>

<script>
  import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
  import FooterComponent from "@/components/Footers/Footer.vue";

  export default {
    name: 'App',
    data() {
      return {}
    },
    methods: {
    },
    created: function() {
      this.$store.dispatch('eth/init')
    },
    components: {
      IndexNavbar,
      FooterComponent,
    },
  }
</script>