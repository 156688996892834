import Airdrop from '@/contracts/Airdrop.json';
import {
  Web3Provider
} from "@ethersproject/providers";
import {
  Contract
} from 'ethers';

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

const AIRDROP_ADDRESS = process.env.VUE_APP_AIRDROP_ADDRESS


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        56: "https://bsc-dataseed.binance.org/",
      },
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

export default {
  namespaced: true,
  state: {
    provider: null,
    account: null,
    airdropContract: null
  },
  getters: {
    account: state => state.account,
    airdropContract: state => state.airdropContract
  },
  mutations: {
    setProvider(state, payload) {
      state.provider = payload.provider;
    },
    setAccount(state, payload) {
      state.account = payload.account;
    },
    setAirdrop(state, payload) {
      state.airdropContract = payload.airdropContract;
    },
  },
  actions: {
    async setProvider({
      commit
    }, rawProvider) {
      const connectedProvider = new Web3Provider(rawProvider, "any");
      const signer = connectedProvider.getSigner();
      const connectedAddress = await signer.getAddress();
      const contract = new Contract(AIRDROP_ADDRESS, Airdrop.abi, signer);

      commit('setProvider', {
        provider: connectedProvider
      });
      commit('setAccount', {
        account: connectedAddress
      });
      commit('setAirdrop', {
        airdropContract: contract
      });

    },
    async redeem({
      state
    }, {id, amount, proof}) {
      try {
        const airdropContract = state.airdropContract;
        console.log(amount);
        console.log(proof);
        const tx = await airdropContract.redeem(id, state.account, amount, proof);
        await tx.wait();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async init({
      commit
    }) {
      if (web3Modal.cachedProvider) {
        const provider = await web3Modal.connect();
        const connectedProvider = new Web3Provider(provider, "any");
        const signer = connectedProvider.getSigner();
        const connectedAddress = await signer.getAddress();
        const contract = new Contract(AIRDROP_ADDRESS, Airdrop.abi, signer);
        commit('setProvider', {
          provider: connectedProvider
        });
        commit('setAccount', {
          account: connectedAddress
        });
        commit('setAirdrop', {
          airdropContract: contract
        });
      }
    },
    async connect({
      commit
    }) {
      const provider = await web3Modal.connect();
      const connectedProvider = new Web3Provider(provider, "any");
      const signer = connectedProvider.getSigner();
      const connectedAddress = await signer.getAddress();
      const contract = new Contract(AIRDROP_ADDRESS, Airdrop.abi, signer);

      commit('setProvider', {
        provider: connectedProvider
      });
      commit('setAccount', {
        account: connectedAddress
      });
      commit('setAirdrop', {
        airdropContract: contract
      });
    },
    async disconnect() {
      await web3Modal.clearCachedProvider();
      setTimeout(() => {
        window.location.reload()
      }, 1)
    }
  },
}